<template>
    <div class="radiotherapy-detail">
        <div class="patient-info">
            <div class="info-head">
                <div class="head-txt">
                    <p class="name">{{detailInfo.patientName || '--'}}</p>
                    <p class="txt">{{$t("radiotherapy.patient")}}</p>
                </div>
                <i class="hr"></i>
                <div class="head-txt">
                    <p class="name">{{detailInfo.checkRoomName || '--'}}</p>
                    <p class="txt">{{$t("radiotherapy.treatmentRoom")}}</p>
                </div>
            </div>
            <div class="patient-info">
                <div class="info-item">
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.treatmentPlan")}}：</span>
                        <span class="value">{{detailInfo.infoPlan}}</span>
                    </p>
                </div>
                <div class="info-item">
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.treatmentMethods")}}：</span>
                        <span class="value">{{detailInfo.infoMethod}}</span>
                    </p>
                </div>
                <div class="info-item">
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.treatmentsite")}}：</span>
                        <span class="value">{{detailInfo.infoBodyPart || '--'}}</span>
                    </p>
                     <p class="txt">
                        <span class="label">{{$t("radiotherapy.Isocenter")}}：</span>
                        <span class="value">{{detailInfo.infoIsocenter||'--'}}</span>
                    </p>
                </div>
                <div class="info-item">
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.rayEnergy")}}：</span>
                        <span class="value">{{detailInfo.infoRayEnergy||'--'}}</span>
                    </p>
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.fy")}}：</span>
                        <span class="value">{{detailInfo.infoRayField || '--'}}</span>
                    </p>
                </div>
                <div class="info-item">
                    <p class="txt">
                        <span class="label">{{$t("radiotherapy.frequency")}}：</span>
                        <span class="value">{{detailInfo.infoRayTimes||'--'}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="radiotherapy-list">
            <p class="title">{{$t("radiotherapy.name")}}</p>
            <div class="list-container">
                <div class="item-container" :class="{'complete':item.hasDone == 1}" v-for="(item,index) in radiotherapyList" :key="index">
                    <p class="timeInterval">{{item.timeRange || '--'}}</p>
                    <p class="time-date">{{item.date}}（{{getweekday(item.date)}}）</p>
                    <span class="tag">{{item.hasDone == 1?$t("radiotherapy.complete"):$t("radiotherapy.incomplete")}}</span>
                    <i class="icon"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { radiotherapyQuery } from "@/api/user";
import { getweekday } from "@/utils/date";
export default {
    data () {
        return {
            detailInfo:{},
            getweekday,
            radiotherapyList:[],
            patientId:this.$route.query.patientId,
        }
    },
    created () {
        document.title = this.$t("radiotherapy.detail")
        this.getInfoData();
    },
    methods: {
        getInfoData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            radiotherapyQuery({
                patientId:this.patientId
            }).then(res =>{
                toast.clear()
                console.log(res);
                if(res.data.errorCode == 0){
                    if(res.data.data&&res.data.data.length!=0){
                        this.detailInfo = res.data.data[0].patients[0];
                        this.radiotherapyList = []
                        for (let i = 0; i < res.data.data.length; i++) {
                            const it = res.data.data[i];
                            for (let g = 0; g < it.patients.length; g++) {
                                const gt = it.patients[g];
                                this.radiotherapyList.push({...gt,date:it.date})
                            }
                        }
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.radiotherapy-detail{
    background: #fff;
    .patient-info{
        background: #F5F6FA;
        padding: 0.6rem;
        .info-head{
            display: flex;
            padding: 0.6rem 0.6rem 0.9rem 0.6rem;
            background: url("~@/assets/img/radiotherapy-detail-head-back.png") no-repeat;
            align-items: center;
            background-size: 100% 100%;
            position: relative;
            z-index: 9;
            .hr{
                height: 1rem;
                border: 0.02rem solid #FFFFFF;
            }
            .head-txt{
                flex: 1;
                text-align: center;
                .name{
                    font-size: 0.8rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 1.2rem;
                }
                .txt{
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 0.8rem;
                }
            }
        }
        .patient-info{
            margin:-0.5rem 0.4rem 0 0.4rem;
            background: #D9EBFF;
            border-radius: .16rem;
            .info-item{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #606266;
                line-height: 0.8rem;
                margin: 0.1rem 0;
                display: flex;
                .txt{
                    flex: 1;
                    display: flex;
                    .label{
                        flex: 0 0 auto;
                    }
                    .value{
                        flex: 1;
                    }
                }
                .lasttxt{
                    margin-left: 0.4rem;
                }
            }
        }
    }
    .radiotherapy-list{
        padding:0.6rem 0.6rem 1rem 0.6rem;
        .title{
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #181818;
        }   
        .list-container{
            padding-left: 1.2rem;
            margin-top: 0.6rem;
            overflow: auto;
            position: relative;
            &::after{
                position: absolute;
                top: 0;
                height: 100%;
                content: ' ';
                left: 0.58rem;
                // width: 0.02rem;
                border-left: 0.05rem dashed #C0C4CC;
            }
            .item-container{
                margin-bottom: 0.8rem;
                background: #F4F4F5;
                border-radius: 8px;
                border: 1px solid #C0C4CC;
                padding: 0.5rem 0.6rem;
                position: relative;
                .timeInterval{
                    font-size: 0.8rem;
                    font-family: DINAlternate-Bold, DINAlternate;
                    font-weight: bold;
                    color: #303133;
                    line-height: 1rem;
                    letter-spacing: 0.04rem;
                }
                .time-date {
                    font-size: 0.64rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #606266;
                    line-height: 0.9rem;
                }
                .tag{
                    position: absolute;
                    padding: 0 0.3rem;
                    top: 0;
                    background: #909399;
                    right: 0;
                    font-size: 0.44rem;
                    color:#fff;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.6rem;
                    border-radius: 0 0.16rem 0 0.36rem;
                }
                .icon{
                    width: 0.5rem;
                    height: 0.5rem;
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    background: #C0C4CC;
                    border-radius: 50%;
                    left: -0.87rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &::after{
                        content: ' ';
                        width: 50%;
                        height: 50%;
                        background: #FFFFFF;
                        border-radius: 50%;
                    }
                }
            }
            .complete{
                border-color: #67C23A;
                background: #F0F9EB;
                .tag,.icon{
                    background: #67C23A;
                }
            }
        }     
    }
}
</style>